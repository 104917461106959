import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LocalStorageKeys } from "../../../utils";
import { config } from "../../../config";

export const DashboardAPI = createApi({
  reducerPath: "DashboardAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LocalStorageKeys.authToken);
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["DashboardAPI"],
  endpoints: (builder) => ({
    getIndustrylist: builder.query({
      query: () => `/tenant/client/Industrylist`,
      providesTags: ["DashboardAPI"],
    }),
    getdefaultApplications: builder.query({
      query: (data) => {
        return `/tenant/client/defaultApplication?search=${data?.search}&categories=${data?.categories}&industry=${data?.industry}`;
      },
      providesTags: ["DashboardAPI"],
    }),
    SaveDefaultAppData: builder.mutation({
      query: (data) => ({
        url: `/tenant/client/SaveDefaultAppData`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["DashboardAPI"],
    }),
    encryptData: builder.mutation({
      query: (data) => ({
        url: "encryption/encryptdata",
        method: "POST",
        body: {
          data: data.data,
        },
      }),
      invalidatesTags: ["encryptionAPi"],
    }),
    decryptData: builder.mutation({
      query: (data) => ({
        url: "encryption/decryptdata",
        method: "POST",
        body: {
          data: data.data,
        },
      }),
      invalidatesTags: ["encryptionAPi"],
    }),
  }),
});
export const {
  useGetIndustrylistQuery,
  useGetdefaultApplicationsQuery,
  useSaveDefaultAppDataMutation,
  useEncryptDataMutation,
  useDecryptDataMutation,
} = DashboardAPI;
